/**
 * 生徒が持っている生徒タグのつけ外し
 */
export const editStudentTags = /* GraphQL */ `
  mutation editStudentTags($input: EditStudentTagsInput!) {
    editStudentTags(input: $input) {
      id
      tagName
      memo
      color
    }
  }
`;

/**
 * 指導タスクの作成
 */
export const createGuidanceIndividualTask = /* GraphQL */ `
  mutation createGuidanceIndividualTask($input: CreateGuidanceIndividualTaskInput!) {
    createGuidanceIndividualTask(input: $input)
  }
`;
/**
 * 指導タスクの削除
 */
export const deleteGuidanceIndividualTask = /* GraphQL */ `
  mutation deleteGuidanceIndividualTask($input: DeleteGuidanceIndividualTaskInput!) {
    deleteGuidanceIndividualTask(input: $input)
  }
`;
/**
 * 指導タスクの編集
 */
export const updateGuidanceIndividualTask = /* GraphQL */ `
  mutation updateGuidanceIndividualTask($input: UpdateGuidanceIndividualTaskInput!) {
    updateGuidanceIndividualTask(input: $input)
  }
`;
/**
 * 指導タスクの未完了
 */
export const incompleteGuidanceIndividualTask = /* GraphQL */ `
  mutation incompleteGuidanceIndividualTask(
    $input: IncompleteGuidanceIndividualTaskInput!
  ) {
    incompleteGuidanceIndividualTask(input: $input)
  }
`;
/**
 * 指導タスクの完了
 */
export const completeGuidanceIndividualTask = /* GraphQL */ `
  mutation completeGuidanceIndividualTask($input: CompleteGuidanceIndividualTaskInput!) {
    completeGuidanceIndividualTask(input: $input)
  }
`;

/**
 * 指導方針編集
 */
export const registerGuidancePolicy = /* GraphQL */ `
  mutation registerGuidancePolicy(
    $guidancePolicyRegisterInput: GuidancePolicyRegisterInput!
  ) {
    registerGuidancePolicy(guidancePolicyRegisterInput: $guidancePolicyRegisterInput) {
      id
    }
  }
`;

export const createTeacherTask = /* GraphQL */ `
  mutation createTeacherTask($input: CreateTeacherTaskInput!) {
    createTeacherTask(input: $input) {
      id
    }
  }
`;

export const updateTeacherTask = /* GraphQL */ `
  mutation updateTeacherTask($input: UpdateTeacherTaskInput!) {
    updateTeacherTask(input: $input) {
      id
    }
  }
`;

export const deleteTeacherTask = /* GraphQL */ `
  mutation deleteTeacherTask($input: DeleteTeacherTaskInput!) {
    deleteTeacherTask(input: $input) {
      id
    }
  }
`;

/**
 * 講師タスク完了
 */
export const completeTeacherTask = /* GraphQL */ `
  mutation completeTeacherTask($input: CompleteTeacherTaskInput!) {
    completeTeacherTask(input: $input) {
      id
    }
  }
`;
/**
 * 講師タスク未完了
 */
export const incompleteTeacherTask = /* GraphQL */ `
  mutation incompleteTeacherTask($input: IncompleteTeacherTaskInput!) {
    incompleteTeacherTask(input: $input) {
      id
    }
  }
`;

/**
 * 講師タスク完了
 */
export const completeTeacherTaskCheck = /* GraphQL */ `
  mutation completeTeacherTaskCheck($input: CompleteTeacherTaskCheckInput!) {
    completeTeacherTaskCheck(input: $input) {
      id
      completedDate
    }
  }
`;
/**
 * 講師タスク完了
 */
export const incompleteTeacherTaskCheck = /* GraphQL */ `
  mutation incompleteTeacherTaskCheck($input: IncompleteTeacherTaskCheckInput!) {
    incompleteTeacherTaskCheck(input: $input) {
      id
      completedDate
    }
  }
`;

// 生徒教材追加（新規作成）
export const createStudentTextbook = /* GraphQL */ `
  mutation createStudentTextbook($input: StudentTextbookCreateInput!) {
    createStudentTextbook(input: $input) {
      id
      textbookId
      textbookName
      importantFlag
      progressUnitName
      targetLap
      studentGrade {
        id
        grade
      }
      semester {
        id
        semesterName
      }
      subject {
        id
        subjectName
      }
    }
  }
`;
// 講師タスク編集
export const editTeacherTaskStudents = /* GraphQL */ `
  mutation editTeacherTaskStudents($input: EditTeacherTaskStudentsInput!) {
    editTeacherTaskStudents(input: $input) {
      id
    }
  }
`;

// 生徒教材追加（編集）
export const updateStudentTextbook = /* GraphQL */ `
  mutation updateStudentTextbook($input: StudentTextbookUpdateInput!) {
    updateStudentTextbook(input: $input) {
      id
    }
  }
`;

// 教材削除
export const deleteStudentTextbook = /* GraphQL */ `
  mutation deleteStudentTextbook($input: StudentTextbookDeleteInput!) {
    deleteStudentTextbook(input: $input) {
      id
    }
  }
`;

export const createGuidanceKoma = /* GraphQL */ `
  mutation createGuidanceKoma($input: CreateGuidanceKomaInput!) {
    createGuidanceKoma(input: $input) {
      id
      classroom {
        id
        classroomName
      }
      attendanceStatus
      komaTemplate {
        komaName
        id
      }
      subjects {
        id
        subjectName
        categoryTypes {
          guidance {
            id
          }
        }
        subjectArea {
          id
        }
      }
      managementUser {
        __typename
        ... on Teacher {
          managementUserId
          name
        }
        ... on Manager {
          managementUserId
          name
        }
      }
      transferStartedDate
      transferFinishedDate
    }
  }
`;

export const updateGuidanceKoma = /* GraphQL */ `
  mutation updateGuidanceKoma($input: UpdateGuidanceKomaInput!) {
    updateGuidanceKoma(input: $input) {
      id
      classroom {
        id
        classroomName
      }
      attendanceStatus
      komaTemplate {
        komaName
        id
      }
      subjects {
        id
        subjectName
        categoryTypes {
          guidance {
            id
          }
        }
        subjectArea {
          id
        }
      }
      managementUser {
        __typename
        ... on Teacher {
          managementUserId
          name
        }
        ... on Manager {
          managementUserId
          name
        }
      }
      transferOriginGuidanceKoma {
        komaDate
        komaTemplate {
          komaName
        }
      }
      transferStartedDate
      transferFinishedDate
    }
  }
`;
export const deleteGuidanceKoma = /* GraphQL */ `
  mutation deleteGuidanceKoma($input: DeleteGuidanceKomaInput!) {
    deleteGuidanceKoma(input: $input) {
      id
      komaTemplate {
        komaName
        id
      }
    }
  }
`;

export const bulkRegisterGuidanceKomas = /* GraphQL */ `
  mutation bulkRegisterGuidanceKomas($input: GuidanceKomasBulkRegisterInput!) {
    bulkRegisterGuidanceKomas(input: $input)
  }
`;
export const bulkUpdateGuidanceKomas = /* GraphQL */ `
  mutation bulkUpdateGuidanceKomas($input: GuidanceKomasBulkUpdateInput!) {
    bulkUpdateGuidanceKomas(input: $input)
  }
`;

export const transferGuidanceKoma = /* GraphQL */ `
  mutation transferGuidanceKoma($input: TransferGuidanceKomaInput!) {
    transferGuidanceKoma(input: $input) {
      id
      komaDate
      classroom {
        id
        classroomName
      }
      attendanceStatus
      komaTemplate {
        komaName
        id
      }
      subjects {
        id
        subjectName
        categoryTypes {
          guidance {
            id
          }
        }
        subjectArea {
          id
        }
      }
      managementUser {
        __typename
        ... on Teacher {
          managementUserId
          name
        }
        ... on Manager {
          managementUserId
          name
        }
      }
      transferOriginGuidanceKoma {
        komaDate
        komaTemplate {
          komaName
        }
      }
      transferStartedDate
      transferFinishedDate
    }
  }
`;

/**
 * 生徒契約
 */
export const createStudentContract = /* GraphQL */ `
  mutation createStudentContract($input: StudentContractCreateInput!) {
    createStudentContract(input: $input) {
      id
    }
  }
`;
export const updateStudentContract = /* GraphQL */ `
  mutation updateStudentContract($input: StudentContractUpdateInput!) {
    updateStudentContract(input: $input) {
      id
    }
  }
`;
export const deleteStudentContract = /* GraphQL */ `
  mutation deleteStudentContract($input: StudentContractDeleteInput!) {
    deleteStudentContract(input: $input) {
      id
    }
  }
`;

/**
 * 指導報告
 */
// 学習アプリ(aim)
export const registerAimAtReports = /* GraphQL */ `
  mutation registerAimAtReports($input: AimAtReportsRegisterInput!) {
    registerAimAtReports(input: $input) {
      __typename
    }
  }
`;

export const registerGuidanceKomaTextbookStudy = /* GraphQL */ `
  mutation registerGuidanceKomaTextbookStudy(
    $input: GuidanceKomaTextbookStudyRegisterInput!
  ) {
    registerGuidanceKomaTextbookStudy(input: $input)
  }
`;

export const deleteGuidanceKomaTextbookStudy = /* GraphQL */ `
  mutation deleteGuidanceKomaTextbookStudy(
    $input: GuidanceKomaTextbookStudyDeleteInput!
  ) {
    deleteGuidanceKomaTextbookStudy(input: $input) {
      id
    }
  }
`;
// 指導評価
export const registerGuidanceReviews = /* GraphQL */ `
  mutation registerGuidanceReviews(
    $guidanceReportId: Int!
    $guidanceReviews: [GuidanceReviewRegisterInput!]!
  ) {
    registerGuidanceReviews(
      guidanceReportId: $guidanceReportId
      guidanceReviews: $guidanceReviews
    ) {
      id
    }
  }
`;
// 保護者への報告
export const registerParentReport = /* GraphQL */ `
  mutation registerParentReport($input: ParentReportRegisterInput!) {
    registerParentReport(input: $input) {
      id
    }
  }
`;

/**
 * 指導報告 テスト追加
 */
export const createGuidanceCustomTest = /* GraphQL */ `
  mutation createGuidanceCustomTest($input: GuidanceCustomTestCreateInput!) {
    createGuidanceCustomTest(input: $input) {
      id
    }
  }
`;

/**
 * 指導報告 テスト編集
 */
export const updateGuidanceCustomTest = /* GraphQL */ `
  mutation updateGuidanceCustomTest($input: GuidanceCustomTestUpdateInput!) {
    updateGuidanceCustomTest(input: $input) {
      id
    }
  }
`;
/**
 * 指導報告 テスト編集
 */
export const deleteGuidanceCustomTest = /* GraphQL */ `
  mutation deleteGuidanceCustomTest($input: GuidanceCustomTestDeleteInput!) {
    deleteGuidanceCustomTest(input: $input) {
      id
    }
  }
`;

export const createGuidanceJukuTest = /* GraphQL */ `
  mutation createGuidanceJukuTest(
    $guidanceJukuTestCreateInput: GuidanceJukuTestCreateInput!
  ) {
    createGuidanceJukuTest(guidanceJukuTestCreateInput: $guidanceJukuTestCreateInput) {
      id
    }
  }
`;

/**
 * 指導報告 テスト編集
 */
export const updateGuidanceJukuTest = /* GraphQL */ `
  mutation updateGuidanceJukuTest(
    $guidanceJukuTestUpdateInput: GuidanceJukuTestUpdateInput!
  ) {
    updateGuidanceJukuTest(guidanceJukuTestUpdateInput: $guidanceJukuTestUpdateInput) {
      id
    }
  }
`;
/**
 * 指導報告 テスト編集
 */
export const deleteGuidanceJukuTest = /* GraphQL */ `
  mutation deleteGuidanceJukuTest(
    $guidanceJukuTestDeleteInput: GuidanceJukuTestDeleteInput!
  ) {
    deleteGuidanceJukuTest(guidanceJukuTestDeleteInput: $guidanceJukuTestDeleteInput)
  }
`;

/**
 * 指導報告 完了
 */
export const completeGuidanceReport = /* GraphQL */ `
  mutation completeGuidanceReport($input: GuidanceReportCompleteInput!) {
    completeGuidanceReport(input: $input) {
      id
    }
  }
`;
/**
 * 契約情報の指導コマ反映
 */
export const applyGuidanceKomasFromStudentContract = /* GraphQL */ `
  mutation applyGuidanceKomasFromStudentContract($input: GuidanceKomasApplyInput!) {
    applyGuidanceKomasFromStudentContract(input: $input) {
      date
      guidanceKomas {
        id
      }
    }
  }
`;
export const deleteGuidanceKomasFromStudentContract = /* GraphQL */ `
  mutation deleteGuidanceKomasFromStudentContract($input: GuidanceKomasDeleteInput!) {
    deleteGuidanceKomasFromStudentContract(input: $input)
  }
`;

/**
 * IDで入退室
 */
export const accessRoom = /* GraphQL */ `
  mutation accessRoom($input: RoomAccessInput!) {
    accessRoom(input: $input) {
      __typename
      ... on Student {
        username
        name
        classroom {
          classroomName
        }
        latestLoginInfo {
          currentStatus
        }
        guidanceKomas {
          id
          komaTemplate {
            komaName
          }
          komaDate
          managementUser {
            __typename
            ... on Teacher {
              id
              username
              name
            }
            ... on Manager {
              id
              username
              name
            }
          }
          subjects {
            subjectName
          }
        }
      }
      ... on Teacher {
        username
        name
        mainClassroom {
          classroomName
        }
        latestLoginInfo {
          currentStatus
        }
      }
      ... on Manager {
        username
        name
        latestLoginInfo {
          currentStatus
        }
      }
    }
  }
`;

/**
 * 定期試験
 */

// ---- 学校デフォルト試験科目 ----
// 科目編集
export const updateDefaultSchoolExamSubject = /* GraphQL */ `
  mutation updateDefaultSchoolExamSubject($input: DefaultSchoolExamSubjectUpdateInput!) {
    updateDefaultSchoolExamSubject(input: $input) {
      schoolScoreSubjectId
    }
  }
`;

// ---- 学校 後から追加する試験科目 ----
// 科目作成
export const createAdditionalSchoolExamSubject = /* GraphQL */ `
  mutation createAdditionalSchoolExamSubject(
    $input: AdditionalSchoolExamSubjectCreateInput!
  ) {
    createAdditionalSchoolExamSubject(input: $input) {
      schoolScoreSubjectId
    }
  }
`;
// 科目編集
export const updateAdditionalSchoolExamSubject = /* GraphQL */ `
  mutation updateAdditionalSchoolExamSubject(
    $input: AdditionalSchoolExamSubjectUpdateInput!
  ) {
    updateAdditionalSchoolExamSubject(input: $input) {
      schoolScoreSubjectId
    }
  }
`;
// 科目削除
export const deleteAdditionalSchoolExamSubject = /* GraphQL */ `
  mutation deleteAdditionalSchoolExamSubject(
    $input: AdditionalSchoolExamSubjectDeleteInput!
  ) {
    deleteAdditionalSchoolExamSubject(input: $input) {
      schoolScoreSubjectId
    }
  }
`;
// 科目一括登録
export const bulkRegisterSchoolExamSubjects = /* GraphQL */ `
  mutation bulkRegisterSchoolExamSubjects(
    $inputs: [SchoolExamSubjectsBulkRegisterInput!]!
  ) {
    bulkRegisterSchoolExamSubjects(inputs: $inputs) {
      ... on DefaultSchoolExamSubject {
        schoolExamSubjectId
      }
    }
  }
`;

//  ---- 生徒オリジナル定期試験 ----

// 試験作成
export const createOriginalExam = /* GraphQL */ `
  mutation createOriginalExam($input: OriginalExamCreateInput!) {
    createOriginalExam(input: $input) {
      id
    }
  }
`;
// 試験編集
export const updateOriginalExam = /* GraphQL */ `
  mutation updateOriginalExam($input: OriginalExamUpdateInput!) {
    updateOriginalExam(input: $input) {
      id
    }
  }
`;
// 試験削除
export const deleteOriginalExam = /* GraphQL */ `
  mutation deleteOriginalExam($input: OriginalExamDeleteInput!) {
    deleteOriginalExam(input: $input) {
      id
    }
  }
`;

// 科目作成
export const createOriginalExamSubject = /* GraphQL */ `
  mutation createOriginalExamSubject($input: OriginalExamSubjectCreateInput!) {
    createOriginalExamSubject(input: $input) {
      originalExamSubjectId
    }
  }
`;
// 科目編集
export const updateOriginalExamSubject = /* GraphQL */ `
  mutation updateOriginalExamSubject($input: OriginalExamSubjectUpdateInput!) {
    updateOriginalExamSubject(input: $input) {
      originalExamSubjectId
    }
  }
`;
// 科目削除
export const deleteOriginalExamSubject = /* GraphQL */ `
  mutation deleteOriginalExamSubject($input: OriginalExamSubjectDeleteInput!) {
    deleteOriginalExamSubject(input: $input) {
      originalExamSubjectId
    }
  }
`;
// 科目一括登録
export const bulkRegisterOriginalExamSubjects = /* GraphQL */ `
  mutation bulkRegisterOriginalExamSubjects(
    $inputs: [OriginalExamSubjectsBulkRegisterInput!]!
  ) {
    bulkRegisterOriginalExamSubjects(inputs: $inputs) {
      originalExamSubjectId
    }
  }
`;

/**
 * 講師シフトコマCRUD
 */
export const createTeacherShiftKoma = /* GraphQL */ `
  mutation createTeacherShiftKoma($input: TeacherShiftKomaCreateInput!) {
    createTeacherShiftKoma(input: $input) {
      id
      komaTemplate {
        komaName
        id
      }
      attendanceStatus
      classroom {
        id
        classroomName
      }
      subjects {
        id
        subjectName
        subjectArea {
          id
          subjectAreaName
        }
        categoryTypes {
          guidance {
            id
          }
        }
      }
    }
  }
`;
export const updateTeacherShiftKoma = /* GraphQL */ `
  mutation updateTeacherShiftKoma($input: TeacherShiftKomaUpdateInput!) {
    updateTeacherShiftKoma(input: $input) {
      id
      komaTemplate {
        id
        komaName
        komaOrder
      }
      attendanceStatus
      classroom {
        id
        classroomName
      }
      subjects {
        id
        subjectName
        subjectArea {
          id
          subjectAreaName
        }
        categoryTypes {
          guidance {
            id
          }
        }
      }
    }
  }
`;
export const deleteTeacherShiftKoma = /* GraphQL */ `
  mutation deleteTeacherShiftKoma($input: TeacherShiftKomaDeleteInput!) {
    deleteTeacherShiftKoma(input: $input) {
      id
      komaTemplate {
        id
        komaName
        komaOrder
      }
      attendanceStatus
      classroom {
        id
        classroomName
      }
      subjects {
        id
        subjectName
        subjectArea {
          id
          subjectAreaName
        }
      }
    }
  }
`;
/**
 * 講師シフト 一括登録
 */
export const bulkRegisterTeacherShifts = /* GraphQL */ `
  mutation bulkRegisterTeacherShifts($input: TeacherShiftsBulkRegisterInput!) {
    bulkRegisterTeacherShifts(input: $input)
  }
`;

/**
 *
 * 講師固定シフト
 *
 */
export const createFixedTeacherShiftKoma = /* GraphQL */ `
  mutation createFixedTeacherShiftKoma($input: FixedTeacherShiftKomaCreateInput!) {
    createFixedTeacherShiftKoma(input: $input) {
      id
      dayOfWeek
      komaTemplate {
        id
        komaName
        komaOrder
      }

      managementUser {
        ... on Manager {
          managementUserId
          name
        }
        ... on Teacher {
          managementUserId
          name
        }
      }
      classroom {
        id
        classroomName
      }
      subjects {
        id
        subjectArea {
          id
        }
        categoryTypes {
          guidance {
            id
          }
        }
        subjectName
      }
    }
  }
`;

export const updateFixedTeacherShiftKoma = /* GraphQL */ `
  mutation updateFixedTeacherShiftKoma($input: FixedTeacherShiftKomaUpdateInput!) {
    updateFixedTeacherShiftKoma(input: $input) {
      id
      dayOfWeek

      komaTemplate {
        id
        komaName
        komaOrder
      }

      managementUser {
        ... on Manager {
          managementUserId
          name
        }
        ... on Teacher {
          managementUserId
          name
        }
      }
      classroom {
        id
        classroomName
      }
      subjects {
        id
        subjectArea {
          id
        }
        categoryTypes {
          guidance {
            id
          }
        }
        subjectName
      }
    }
  }
`;

export const deleteFixedTeacherShiftKoma = /* GraphQL */ `
  mutation deleteFixedTeacherShiftKoma($input: FixedTeacherShiftKomaDeleteInput!) {
    deleteFixedTeacherShiftKoma(input: $input) {
      id
      dayOfWeek

      komaTemplate {
        id
        komaName
        komaOrder
      }

      managementUser {
        ... on Manager {
          managementUserId
          name
        }
        ... on Teacher {
          managementUserId
          name
        }
      }
      classroom {
        id
        classroomName
      }
      subjects {
        id
        subjectArea {
          id
        }
        categoryTypes {
          guidance {
            id
          }
        }
        subjectName
      }
    }
  }
`;

export const applyFixedTeacherShiftKomas = /* GraphQL */ `
  mutation applyFixedTeacherShiftKomas($input: FixedTeacherShiftKomasApplyInput!) {
    applyFixedTeacherShiftKomas(input: $input)
  }
`;

/**
 * 講師共有コメント
 */
/**
 * ピン
 */
// ピン留め
export const pinTeacherSharedComment = /* GraphQL */ `
  mutation pinTeacherSharedComment($input: TeacherSharedCommentPinInput!) {
    pinTeacherSharedComment(input: $input) {
      id
      pinDate
    }
  }
`;
// ピン留めを外す
export const unpinTeacherSharedComment = /* GraphQL */ `
  mutation unpinTeacherSharedComment($input: TeacherSharedCommentPinInput!) {
    unpinTeacherSharedComment(input: $input) {
      id
      pinDate
    }
  }
`;

/**
 * リアクション
 */
// リアクションをする
export const reactTeacherSharedComment = /* GraphQL */ `
  mutation reactTeacherSharedComment($input: TeacherSharedCommentReactInput!) {
    reactTeacherSharedComment(input: $input) {
      id
      managementUser {
        __typename
        ... on Teacher {
          id
          managementUserId
          username
          name
        }
        ... on Manager {
          id
          managementUserId
          username
          name
        }
      }
      reaction
    }
  }
`;
// リアクションを外す
export const unReactTeacherSharedComment = /* GraphQL */ `
  mutation unReactTeacherSharedComment($input: TeacherSharedCommentReactInput!) {
    unReactTeacherSharedComment(input: $input) {
      id
      managementUser {
        __typename
        ... on Teacher {
          id
          managementUserId
          username
          name
        }
        ... on Manager {
          id
          managementUserId
          username
          name
        }
      }
      reaction
    }
  }
`;

/**
 * 既読
 */
export const bulkReadTeacherSharedComments = /* GraphQL */ `
  mutation bulkReadTeacherSharedComments($input: TeacherSharedCommentsBulkReadInput!) {
    bulkReadTeacherSharedComments(input: $input)
  }
`;

/**
 * crud
 */
// 作成
export const createTeacherSharedComment = /* GraphQL */ `
  mutation createTeacherSharedComment($input: TeacherSharedCommentCreateInput!) {
    createTeacherSharedComment(input: $input) {
      id
    }
  }
`;
// 編集
export const updateTeacherSharedComment = /* GraphQL */ `
  mutation updateTeacherSharedComment($input: TeacherSharedCommentUpdateInput!) {
    updateTeacherSharedComment(input: $input) {
      id
    }
  }
`;
// 削除
export const deleteTeacherSharedComment = /* GraphQL */ `
  mutation deleteTeacherSharedComment($input: TeacherSharedCommentDeleteInput!) {
    deleteTeacherSharedComment(input: $input) {
      id
    }
  }
`;

export const updateStudentSemester = /* GraphQL */ `
  mutation updateStudentSemester($input: StudentSemesterUpdateInput!) {
    updateStudentSemester(input: $input) {
      id
    }
  }
`;

export const registerTotalTextbookStudy = /* GraphQL */ `
  mutation registerTotalTextbookStudy($input: TotalTextbookStudyRegisterInput!) {
    registerTotalTextbookStudy(input: $input)
  }
`;

export const registerWeeklyStudyPlanMemo = /* GraphQL */ `
  mutation registerWeeklyStudyPlanMemo($input: WeeklyStudyPlanMemoRegisterInput!) {
    registerWeeklyStudyPlanMemo(input: $input) {
      weeklyStudyPlanId
      memo
      lastUpdatedBy {
        ... on Teacher {
          managementUserId
          name
        }
        ... on Manager {
          managementUserId
          name
        }
      }
      updatedDate
    }
  }
`;

export const createStudyPlan = /* GraphQL */ `
  mutation createStudyPlan($input: StudyPlanCreateInput!) {
    createStudyPlan(input: $input)
  }
`;

export const updateStudyPlan = /* GraphQL */ `
  mutation updateStudyPlan($input: StudyPlanUpdateInput!) {
    updateStudyPlan(input: $input)
  }
`;

export const registerStudyPlanStudentTextbook = /* GraphQL */ `
  mutation registerStudyPlanStudentTextbook(
    $input: StudyPlanStudentTextbookRegisterInput!
  ) {
    registerStudyPlanStudentTextbook(input: $input)
  }
`;
export const moveGuidanceIndividualTasks = /* GraphQL */ `
  mutation moveGuidanceIndividualTasks($inputs: [GuidanceIndividualTasksMoveInput!]!) {
    moveGuidanceIndividualTasks(inputs: $inputs)
  }
`;
